.formModal
  &.ant-modal
    position: relative
    &.loading
      & .ant-modal-header
        display: none

      & .ant-modal-close
        display: none

    & .ant-modal-header
      border-bottom: transparent

    & .ant-modal-title
      text-align: left
      font: 20px/6px "SF Pro Display Bold"
      letter-spacing: 0
      color: $popup-title-color
      margin: 16px 0

    & .ant-modal-body
      padding: 0 0 24px 0
      & .overflowContent
        min-height: 250px
        max-height: 70vh
        overflow-y: auto

    & .MainModal
      overflow: auto
      &.defaultGames
        min-height: 250px
      &.infoPopUp
        padding: 0 0 24px 0
        & .MainModal--parts:first-child
          border-right: 0
      &--parts
        & .durations
          padding: 15px 24px
          background-color: #F8F8FA
        & .divider
          border: none
          height: 1px
          /* Set the hr color */
          color: #DCDCE4 /* old IE */
          background: #DCDCE4 0% 0% no-repeat padding-box
          opacity: 1
          margin-bottom: 16px
        &--content
          padding: 0 24px

        & .withList
          height: 540px
          padding: 0 0 0 0

        &:first-child
          border-right: 1px solid #DCDCE4

        & .ant-col.ant-form-item-label
          text-align: left
          font: 14px/22px "SF Pro Display Regular"
          letter-spacing: 0
          color: #191738
          padding: 0

      &--footer
        margin-bottom: 0 !important
        border-top: 1px solid #f0f0f0
        padding: 24px 20px 0 24px

        & .ant-form-item-control-input-content
          display: flex
          align-items: center
          justify-content: flex-end

        & button
          margin: 0 4px
        & button.submit:hover
          & + .error_box
            display: block
        & .error_box
          position: absolute
          left: 100%
          top: 0
          display: none
          width: 266px
          background: #161829 0 0 no-repeat padding-box
          box-shadow: 0 0 26px #6371dd29
          border-radius: 4px
          opacity: 1
          padding: 13px 20px
          color: #E25F5F
    & .add-button
      font-size: 25px
      position: absolute
      bottom: 0px
      left: 25px
      z-index: 1
      cursor: pointer
      margin-bottom: 0px
      .anticon
        color: rgba($project-text-color, 0.7)
        &:hover
          color: $project-text-color
        &:focus, &:active
          color: $icon-focus-color
.tabMode
  width: 100%
  text-align: center
  padding: 0 24px 24px 24px
  & .ant-radio-group.ant-radio-group-outline
    background: #F0F2F5 0 0 no-repeat padding-box
    border-radius: 4px
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    &.invalid
      background: #FFF1F0 0 0 no-repeat padding-box
      border: 1px solid #FF4D4F
      border-radius: 4px
    & .ant-radio-button-wrapper
      min-width: calc(100% / 2)
      text-align: center
      background: transparent
      border: transparent
      &:hover
        color: rgba($botton-default-color, 0.7)
      &:focus
        color: $botton-default-color
      &:before
        display: none
      &.invalid
        color: rgb(226, 95, 95, 0.9)
      &.ant-radio-button-wrapper-checked
        background: $selected-tab-color 0 0 no-repeat padding-box
        border: 0.5px solid $selected-tab-border-color
        border-radius: 4px
        text-align: center
        font: 14px/20px "Red Hat Display Medium"
        letter-spacing: 0
        color: #FFFFFF