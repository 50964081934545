@import "../../assets/sass/base/_colors"

.ant-menu-inline .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title,
.ant-menu-vertical .ant-menu-item, .ant-menu-vertical .ant-menu-submenu-title
    color: #4D4D4D
    background: #FFFFFF
    padding-left: 20px !important
.ant-menu
    background: transparent !important
.ant-menu-submenu
    .ant-menu-submenu-title
        i
            color: #4D4D4D
        span
            margin-left: 10px
            color: #4D4D4D

.ant-menu-inline-collapsed
    width: inherit !important
    .ant-menu-submenu
        .ant-menu-submenu-title
            span
                opacity: 0
                transition: opacity .3s
.ant-menu-item-selected a
    color: #4D4D4D
.ant-menu-sub.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected
    color: $project-global-color
.ant-menu-inline .ant-menu-sub .ant-menu-item:hover::before
    border-right-color: $project-global-color
.left-bar-block
    .ant-menu-item
        a
            text-align: left
            font: 14px/24px "SF Pro Display Regular"
            letter-spacing: 0
            color: #191738
            opacity: 1
        .ant-menu-title-content
            margin-left: 20px
.ant-layout-sider .ant-layout-sider-children
    padding-top: 16px

.ant-menu-inline .ant-menu-selected::after,
.ant-menu-vertical .ant-menu-item-selected::after
    transform: scaleY(1) !important
    opacity: 1 !important
    transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.15s cubic-bezier(0.645, 0.045, 0.355, 1)!important
.ant-menu-inline-collapsed-tooltip a
    color: $project-global-color !important